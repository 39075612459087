.login-form-admin {
  &__input {
    color: white !important;
  }
  .ui.button.primary {
    margin-top: 20px;
    background-color: #26788b;
    &:hover{
      background: #30a3bd,
    }
  }
}

// .Prueba {
//   background: white !important;
// }
