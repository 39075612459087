.table-users-admin {
  background-color: rgba(255, 255, 255, 0.85) !important;
  td {
    &.status {
      .icon {
        &.close {
          color: #f00;
        }
        &.check {
          color: #008000;
        }
      }
    }
  }
}
