.add-edit-insumo-form {

  .ui.primary.button {
    margin-top: 20px;
    background-color: #26788b !important;
  }
}

.LoadingButton{
  display: flex;
  justify-content: right;
  &__button{
    width: 200px;
  }
}