.table-categoria-admin {
  td {
    &.status {
      .icon {
        &.close {
          color: #f00;
        }
        &.check {
          color: #008000;
        }
      }
    }
  }
}

.titulo_color {
  background-color: rgba(58, 184, 174, 0.747) !important;
}
.back_positive{
  background-color: rgba(60, 200, 60, 0.40);
  font-weight: bold;
  color: #016e01;
  // &:hover{
  //   background-color: rgba(60, 200, 60, 0.30) !important;
  // }
}

.back_warning{
  background-color: rgba(200, 198, 60, 0.4);
  font-weight: bold;
  color: #838100;
}

.back_negative{
  background-color: rgba(200, 60, 60, 0.4);
  font-weight: bold;
  color: #b80000;
}