.add-edit-categoria-form {
  &__desechable {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .ui.checkbox {
      margin-right: 10px;
    }
  }
  .ui.primary.button {
    margin-top: 20px;
    background-color: #26788b !important;
  }
}