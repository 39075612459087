.modal-basic {
  // border-radius: 15px !important;
  // overflow: hidden;

  &__header {
    background-color: #2C6975 !important;
    color: white !important;
  }

  &__content {
    background-color: #FFFFFF !important;
  }
}
