.error404{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(315deg, #95c591, #34868d, #225182);
  
  &__content{
    background-color: rgba(255, 255, 255, 0.2);

    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(227, 82, 82, 0.3);
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);

    &__logo{
        color: rgb(255, 255, 255);
        margin-top: 20px;
        margin-bottom: 10px;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 10px 10px;
        border-radius: 10px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
        >b {
            margin: 10px;
            font-size: 45px;
        }
    }

    &__button{
        margin-top: 50px !important;
        margin-bottom: 20px !important;
    }

  }
}