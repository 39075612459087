.pie-acopio{
  display: flex;
  justify-content: center;
  &__button-cancelar{
    width: 200px;
  }
  &__button-enviar{
    width: 200px;
  }
}

.table-acopio-admin {
  td {
    &.status {
      .icon {
        &.close {
          color: #f00;
        }
        &.check {
          color: #008000;
        }
      }
    }
  }
}