.login-admin {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(315deg, #95c591, #34868d, #225182);

  &__content {
    background-color: rgb(255, 255, 255);
    padding: 30px 30px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);

    > h1 {
      font-size: 35px;
      text-align: center;
      margin-bottom: 40px;
      color: #26788b;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      &__users {
        color: #26788b;
      }
    }
  }
}
