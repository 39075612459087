.table-insumo-admin {
  td {
    &.status {
      .icon {
        &.close {
          color: #f00;
        }
        &.check {
          color: #008000;
        }
      }
    }
  }
}