.header-page-admin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > h1 {
    color: rgb(0, 0, 0) !important;
  }
  > h2 {
    margin: 0;
  }
}
