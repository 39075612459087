.add-edit-user-form {
  &__active,
  &__staff {
    display: flex;
    align-items: center;
    margin-left: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    .ui.checkbox {
      margin-right: 10px;
    }
  }
}