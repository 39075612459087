.add-edit-user-form {
  &__active,
  &__staff {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .ui.checkbox {
      margin-right: 10px;
    }
  }

  .ui.primary.button {
    margin-top: 20px;
    background-color: #26788b !important;
  }
}

.Apellido {
  margin-left: 10px;
}
.datosPersonales{
  padding: 0px;
  margin-top: 0px ;
  margin-bottom: 0px ;
}
.datoContacto{
  margin-top: 10px;
  margin-bottom: 0 ;
}

.botones{
  display: flex;
  align-items: center;
  &__agregar{
    margin: 5px !important;
  }
}
.LoadingButton{
  display: flex;
  justify-content: right;
  &__button{
    width: 200px;
  }
}
// .Prueba {
//   height: 20px !important;
// }
